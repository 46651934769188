<template>
  <b-sidebar
    id="add-new-project-sidebar"
    :visible="isAddNewProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="50em"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('projects.add_project') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!-- client_id -->
          <validation-provider
            v-if="!propClientId"
            #default="validationContext"
            name="client_id"
            rules="required"
          >
            <b-form-group
              :label="$t('projects.select_client')"
              label-for="client_id"
            >
              <template v-slot:label>
                {{ $t('projects.select_client') }}
                <code>*</code>
              </template>
              <v-select
                id="client_id"
                v-model="projectData.client_id"
                autofocus
                :state="getValidationState(validationContext)"
                :options="clientsDropDown"
                :reduce="clientsDropDown => clientsDropDown.id"
                label="name"
                @search="(search, loading) => {
                  if(search.length > 2){
                    loading(true)
                    getUserClients(search).then(() => loading(false))
                  }
                }"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <span v-if="search.length < 3"> {{ $t('Type at least 3 characters to search') }} </span>
                    <span v-else > {{ $t('No results found for') }} <em>{{ search }}</em>. </span>
                  </template>
                  <em v-else style="opacity: 0.5">{{ $t('Start typing to search for a client') }}</em>
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <b-form-row>
            <b-col
              cols="12"
              lg="8"
            >
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="surname"
                >
                  <template v-slot:label>
                    {{ $t('projects.name') }}
                    <code>*</code>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="projectData.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              lg="4"
            >
              <!-- reference_code -->
              <validation-provider
                #default="validationContext"
                name="reference_code"
              >
                <b-form-group
                  :label="$t('projects.code')"
                  label-for="reference_code"
                >
                  <b-form-input
                    id="reference_code"
                    v-model="projectData.reference_code"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col
              cols="12"
              lg="6"
            >
              <!-- expected_start_date -->
              <validation-provider
                #default="validationContext"
                name="expected_start_date"
              >
                <b-form-group
                  :label="$t('projects.expected_start_date')"
                  label-for="expected_start_date"
                >
                  <b-form-datepicker
                    v-model="projectData.expected_start_date"
                    :initial-date="projectData.expected_start_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <!-- expected_finish_date -->
              <validation-provider
                #default="validationContext"
                name="expected_finish_date"
              >
                <b-form-group
                  :label="$t('projects.expected_finish_date')"
                  label-for="expected_finish_date"
                >
                  <b-form-datepicker
                    v-model="projectData.expected_finish_date"
                    :initial-date="projectData.expected_finish_date"
                    aria-placeholder="DD/MM/YYY"
                    left
                    calendar-width="100%"
                    locale="es-ES"
                    start-weekday="1"
                    placeholder=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <!-- address1 -->
          <validation-provider
            #default="validationContext"
            name="address1"
          >
            <b-form-group
              :label="$t('projects.address')"
              label-for="address1"
            >
              <b-form-input
                id="address1"
                v-model="projectData.address1"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                class="mb-1"
              />
              <b-form-input
                id="address2"
                v-model="projectData.address2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-row>
            <b-col
              cols="12"
              lg="6"
            >
              <!-- city -->
              <validation-provider
                #default="validationContext"
                name="city"
              >
                <b-form-group
                  :label="$t('projects.city')"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="projectData.city"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="3"
            >
              <!-- postal code -->
              <validation-provider
                #default="validationContext"
                name="posta_code"
              >
                <b-form-group
                  :label="$t('projects.postal_code')"
                  label-for="postal_code"
                >
                  <b-form-input
                    id="postal_code"
                    v-model="projectData.postal_code"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col
              cols="12"
              lg="8"
            >
              <!-- installation_type -->
              <validation-provider
                #default="validationContext"
                name="installation_type"
              >
                <b-form-group
                  :label="$t('projects.installation_type')"
                  label-for="installation_type"
                >
                  <b-form-input
                    id="installation_type"
                    v-model="projectData.installation_type"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              lg="4"
            >
              <!-- power -->
              <validation-provider
                #default="validationContext"
                name="power"
              >
                <b-form-group
                  :label="$t('projects.power')"
                  label-for="power"
                >
                  <b-form-input
                    id="power"
                    v-model="projectData.power"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col
              cols="12"
              lg="6"
            >
              <!-- responsible_id -->
              <validation-provider
                #default="validationContext"
                name="responsible_id"
              >
                <b-form-group
                  :label="$t('projects.responsible')"
                  label-for="responsible_id"
                  cols="12"
                  lg="6"
                >
                  <v-select
                    id="responsible_id"
                    v-model="projectData.responsible_id"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :options="users"
                    :reduce="users => users.id"
                    label="name"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-form-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import router from '@/router'
import {
  BCol, BSidebar, BForm, BFormGroup, BFormInput, BFormDatepicker, BFormInvalidFeedback, BButton, BFormRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
 onMounted, computed, ref, watch,
} from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import useUsersList from "@/views/apps/user/users-list/useUsersList"
import useProjectsList from "@/views/apps/project/projects-list/useProjectsList"

export default {
  components: {
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,
    BFormRow,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProjectSidebarActive',
    event: 'update:is-add-new-project-sidebar-active',
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    client_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const propClientId = (props.client_id && props.client_id !== undefined) ? computed(() => props.client_id) : ''

    const blankProjectData = {
      client_id: propClientId.value,
      name: '',
      reference_code: '',
      expected_start_date: '',
      real_start_date: '',
      expected_finish_date: '',
      real_finish_date: '',
      address1: '',
      city: '',
      installation_type: '',
      power: '',
      responsible_id: '',
    }

    const projectData = ref(JSON.parse(JSON.stringify(blankProjectData)))

    const resetprojectData = () => {
      projectData.value = JSON.parse(JSON.stringify(blankProjectData))
    }

    const onSubmit = () => {
      store.dispatch('model/project/addProject', projectData.value)
          .then(response => {
            emit('refetch-data')
            emit('update:is-add-new-project-sidebar-active', false)
            router.push({ name: 'projects-view', params: { id: response.data.id } })
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetprojectData)

    const { clients, getUserClients } = useProjectsList()
    const { users, getUsers } = useUsersList()

    const clientsDropDown = ref([])
    watch([clients], () => {
      clientsDropDown.value = clients.value
    })

    // onMounted(getUserClients)
    onMounted(getUsers)

    return {
      projectData,
      onSubmit,
      clients,
      users,
      getUserClients,
      clientsDropDown,
      propClientId,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-project-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
