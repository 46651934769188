import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientsList() {
  // Use toast
  const toast = useToast()

  const refClientListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: i18n.t('Name'), sortable: true },
    { key: 'email', label: i18n.t('email'), sortable: true },
    { key: 'city', label: i18n.t('city'), sortable: true },
    { key: 'legal_id', label: i18n.t('legal id'), sortable: true },
    // { key: 'actions', label: i18n.t('Actions'), },
  ]
  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    refClientListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchClients = (ctx, callback) => {
    store
      .dispatch('model/client/fetchClients', {
        search: searchQuery.value,
        pageSize: perPage.value,
        page: currentPage.value,
        order: sortBy.value,
        dir: (isSortDirDesc.value) ? 'DESC' : 'ASC',
      })
      .then(response => {
        const { records, totalRecords } = response.data

        callback(records)
        totalClients.value = totalRecords
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching clients list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,

    refetchData,
  }
}
