import {
 ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios"
import i18n from '@/libs/i18n'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'avatar', label: i18n.t('users.user'), sortable: false },
    { key: 'role', label: i18n.t('users.role'), sortable: true },
    { key: 'name', label: i18n.t('users.name'), sortable: true },
    { key: 'email', label: i18n.t('users.email'), sortable: true },
    { key: 'status', label: i18n.t('users.status'), sortable: true },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('model/user/fetchUsers')
      .then(response => {
        const { records, totalRecords } = response.data

        callback(records)
        totalUsers.value = totalRecords
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const roles = ref([])
  const getUserRoles = async () => {
    // update `props.user` to `user.value` to access the Reference value
    const currentSlug = localStorage.getItem('currentSlug')
    roles.value = await axios
        .get(`/api/v1/${currentSlug}/get-roles`)
        .then(response => response.data.data)
        .catch(error => error)
  }

  const users = ref([])
  const getUsers = async () => {
    // update `props.user` to `user.value` to access the Reference value
    const currentSlug = localStorage.getItem('currentSlug')
    users.value = await axios
        .post(`/api/v1/${currentSlug}/users/list`)
        .then(response => response.data.records)
        .catch(error => error)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Super-admin') return 'primary'
    if (role === 'Office') return 'warning'
    if (role === 'User') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'Pending') return 'warning'
    if (status === 'Active') return 'success'
    if (status === 'Inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    roles,
    getUserRoles,
    users,
    getUsers,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  }
}
