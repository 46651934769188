<template>
    <component :is="clientData === undefined ? 'div' : 'b-card'">
      <project-list-add-new
          :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      />

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="clientData === undefined"
      >
        <h4 class="alert-heading">
          Error fetching client data
        </h4>
        <div class="alert-body">
          No client found with this client id. Check
          <b-link
            class="alert-link"
            :to="{ name: 'apps-clients-list'}"
          >
            Client List
          </b-link>
          for other clients.
        </div>
      </b-alert>

      <b-tabs
        v-if="clientData"
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="ClientIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>
          <client-edit-tab-account
            :client-data="clientData"
            class="mt-2 pt-75"
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Information</span>
          </template>
          <client-edit-tab-information class="mt-2 pt-75" />
        </b-tab>

        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Social</span>
          </template>
          <client-edit-tab-social class="mt-2 pt-75" />
        </b-tab>
      </b-tabs>
    </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import ProjectListAddNew from '@/views/apps/project/projects-list/ProjectListAddNew'
import ClientEditTabAccount from './ClientEditTabAccount.vue'
import ClientEditTabInformation from './ClientEditTabInformation.vue'
import ClientEditTabSocial from './ClientEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ClientEditTabAccount,
    ClientEditTabInformation,
    ClientEditTabSocial,
    ProjectListAddNew,
  },
  setup() {
    const clientData = ref(null)

    const isAddNewProjectSidebarActive = ref(false)

    store.dispatch('model/client/fetchClient', { id: router.currentRoute.params.id })
      .then(response => { clientData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })

    return {
      clientData,
      isAddNewProjectSidebarActive,
    }
  },
}
</script>

<style>

</style>
